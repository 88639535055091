html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Roboto', sans-serif;
  color: #fff;
  background: #F9F9F9 0% 0% no-repeat padding-box;
  height: 100%;
}

.pt-5-rem {
  padding-top: 5rem;
}
.pt-2-rem {
  padding-top: 1.6rem;
}
.form-control:focus {
    color: #000;
    background-color: #fff;
    border-color: #CDCDCD;
    outline: 0;
    box-shadow: none;
}
.div-left {
  padding-right: 0px;
  padding-left: 0px;
}

.side-img {
  display: flex;
  height: calc(100vh - 82px);
}

.div-left-home-img {
  flex: 1;
  background-image: url('../images/first-page.png');
  background-size: cover;
}

.div-left-side-img {
  flex: 1;
  background-image: url('../images/sidebar-img.png');
  background-size: cover;
}

.rm-row-margin {
  margin-right: 0px;
  margin-left: 0px;
}

.div-right {
  flex: 1;
  overflow-y: auto;
  padding-right: 0px;
  padding-left: 0px;
}

.font-otp-nj {
  text-align: left;
  font: normal normal normal 14px/45px Poppins;
  letter-spacing: 0px;
  color: #8B8B8B;
}

.mainlogo-home {
  position: absolute;
  top: 23px;
  left: 19px;
  width: 104px;
  height: 40px;
}

.nav-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-main .NavBar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.navbar-brand-1 img {
  width: 100%;
  height: 100%;
}
.btn {
  border-radius: 8px;
}
.btn-dark {
  background: #919191 0% 0% no-repeat padding-box;
}
.btn-light {
  background: #04A7D0 0% 0% no-repeat padding-box;
}
.navbar-brand-2 {
  display: flex;
  justify-content: space-between;
  padding-top: 1.2rem;
  padding-bottom: 0.3rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.success-imgs {
  position: relative;
  height: 370px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-imgs img {
  /* position: absolute; */
  /* width: 20%;  */
  /* height: 20%; */
  object-fit: cover;
}

.top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 20px;
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.center {
  position: absolute;
  display: flex;
  position: absolute;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: baseline;
}

.center img {
  width: 70%;
}

.back-home {
  left: 202px;
  margin-top: 28px;
}

.back-homeCheckout {
  display: inline-block;
}

.dark-bule-bg:before {
  content: "";
  width: 50%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  background: #4c4c4c;
  opacity: 0.7;
  z-index: -1;
}

.selectedscheme-text {
  margin-top: 18px;
  font-size: 17px;
  margin-bottom: 0;
  font-weight: 400;
}

.selectedscheme-text p {
  margin-bottom: 5px;
}

.email-box {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.main-bg-img {
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  /* background-image: url('./../images/bgImgHome.jpg'); */
}

.intro {
  height: 100vh;
  position: relative;
}

a:hover {
  cursor: pointer;
}

.form-text {
  color: #fff;
}

.user-profile .user-email,
.user-mobile-number,
.user-address {
  font-size: 21px;
  color: #fff;
}

.user-profile .user-name {
  font-size: 28px;
  color: #14CC5E;
}

.br-10 {
  border-radius: 10px !important;
}

/* .card {
  border: 1px solid #fff;
  background-color: #232323e3;
}

.card .card-header {
  background-color: #262626b0;
}

.card-header .btn {
  color: #ffffff;
  font-size: 20px;
} */

.valid label {
  color: #000;
}

.valid {
  position: absolute;
  right: -5px;
  background-color: #fff;
  padding: 0px 13px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  padding-bottom: 3px;
}

.valid .date {
  font-size: 16px;
  font-weight: 500;
  color: #11B837;
}

.bg-grey {
  background-color: #BEBEBE;
  z-index: 11;
}

.bg-image {
  background-image: url('./../images/bgImg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.buy-now-cta {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 8px 20px;
  position: absolute;
  bottom: -25px;
  font: normal normal 600 13px/25px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  z-index: 1;
  border: none;
}

.pt-80 {
  padding-top: 80px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-20 {
  padding-top: 20px;
}

.login-page .form-text h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.login-page .form-text p {
  font-size: 18px;
  margin-top: 20px;
}

.login-page .form-text h3 {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.login-page .form-group label {
  font-weight: 500;
}

.custom-form-control {
  height: 50px;
  font-size: 15px;
  font-weight: 500;
  padding: 0.375rem 1.25rem;
}

.login-page .btn {
  font-size: 20px;
  font-weight: 600;
  border-radius: 58px;
  border: 2px solid;
  padding: 5px 24px 5px 18px;
}

.btn-outline-light:hover {
  color: #ffffff;
  background-color: #028DC5;
  border-color: #0f1d28;
}

.login-page .form-text h5:before {
  content: "";
  width: 27%;
  border-top: 1px solid #ffffff;
  position: absolute;
  bottom: 12px;
  right: 143px;
}

.login-page .form-text h5:after {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #fff;
  position: absolute;
  bottom: 4px;
  right: 128px;
}

.otp-container .form-control {
  padding: 1.275rem 1rem;
  font-weight: 700;
}

.otp-container p {
  font-size: 14px !important;
}

.otp-container label {
  text-transform: uppercase;
  font-weight: 600;
}

.account-box h5 {
  font-size: 20px;
  font-weight: 500;
}

.account-box {
  padding: 15px 18px;
  border-radius: 10px;
}

.account-box .lable {
  font-size: 16px;
  color: #7a7a7a;
}

.account-box .data {
  font-size: 16px;
  color: #0F1D28;
}

.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 600px;
}

.card-header button i {
  position: absolute;
  right: 20px;
  top: 14px;
}

.card-header button {
  font-weight: 600;
}

.card-header {
  padding: 2px 11px;
  background-color: #fff;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: unset;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: #028DC5;
}

.card-body .account-box .data {
  font-weight: 500;
  color: #545454;
}

.card-body .account-box {
  padding: 0px 5px;
}

.card-body .account-box .btn {
  padding: 8px 40px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 22px;
  border-radius: 50px;
}

.btn-dark {
  color: #fff;
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border-color: #04A7D0;
}

.vchange-btn .save-btn {
  /* width: 195px;
  height: 50px; */
  font: normal normal 600 15px / 45px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 2px 33px;
}

.btn-dark:hover {
  color: #fff;
  background-color: #028dc5;
  border-color: #0f99d0;
}

.for-contact p {
  font-size: 16px;
  font-weight: 400;
  color: black;
}

.footer-text p {
  color: black;
}

.get-your-favourite h5 {
  font-size: 28px;
}

.current-scheme {
  position: absolute;
  right: -3px;
  top: 10px;
  font-size: 17px;
  font-weight: 500;
  background-color: #fff;
  color: #000;
  padding: 4px 17px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.grey-bg-transparent {
  background-color: #232323e3;
}

.scheme-box .selected-scheme h5 {
  color: #fff;
}

.scheme-box .selected-scheme h4 {
  color: #fff;
}

.scheme-box .your-plan h4 {
  font-size: 19px;
  color: #fff;
  font-weight: 700;
}

.scheme-box .cuting-price h4 {
  font-size: 19px;
  color: #A7A7A7;
  text-decoration: line-through;
}

.scheme-box .final-price h4 {
  font-size: 19px;
  color: #fff;
  font-weight: 700;
  margin-left: 15px;
}

.scheme-box .next-step a {
  background-color: #1480c4;
  padding: 10px 16px;
  border-radius: 50%;
  margin-left: 30px;
}

.scheme-box .per-day p {
  font-size: 16px;
}

.other-combos {
  color: #fff;
  font-size: 26px;
}

.checkout .selected-scheme h5 {
  color: #fff;
  font-weight: 400;
}

.checkout .scheme-name h4 {
  color: #028DC5;
  font-size: 20px;
}

.checkout .accordion .fa {
  color: #028DC5;
}

.checkout .accordion .card-header button {
  color: #028DC5;
}

.checkout .accordion .collapsed .fa {
  color: #fff;
}

.checkout .accordion .card-header .collapsed {
  color: #fff;
}

.checkout .payment-text p {
  font-size: 16px;
}

.checkout .ptp-btn {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 9px 35px;
  border-radius: 60px;
}

.checkout .card .card-body .form-container label {
  font-size: 15px;
  color: #fff;
}

.checkout .card .card-body .form-container .custom-form-control {
  background-color: #f3f3f329;
  border: 0px;
  color: #ffffff;
  font-weight: 400;
  font-size: 15px;
}

.checkout .card .card-body .form-container label sup {
  color: red;
  top: 0px;
  font-size: 15px;
}

.modal .address-change p {
  font-size: 16px;
  color: #545454;
}

.modal .cancel-btn a {
  font-size: 16px;
  font-weight: 500;
  color: #0F1D28;
}

.selected-scheme .table thead th {
  font-size: 18px;
}

.selected-scheme .table {
  color: #fff;
}

.table .scheme-name h4 {
  font-size: 20px;
}

.table .your-plan h4 {
  font-size: 17px;
}

.table .scheme p {
  font-size: 16px;
  color: #cccccc;
}

.table .final-price h4 {
  font-size: 15px;
}

/* .table .total-final-price p {font-size:20px;color:#fff;font-weight: 500;}
.table .total-final-price .final-price h4 {color:#fff;font-size: 20px;font-weight: 500;} */

.payment-box {
  padding: 12px 14px;
  border: 2px solid #fff;
  border-radius: 6px;
  margin-top: 14px;
}

.payment-cancel-and-continue p a {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.get-your-favourite p {
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
}

.back-to-home p {
  font-size: 16px;
  color: #fff;
}

.terms-conditions ul li p {
  font-size: 16px;
  color: #000;
}

.terms-conditions h3 {
  color: #000;
}

.terms-conditions ul {
  list-style: none;
}

.status {
  padding: 0px 8px;
  border-radius: 3px;
  margin-left: 19px;
}

.live {
  background-color: #14CC5E;
}

.draft {
  background-color: #bbbb43;
}

.your-plan-section:nth-last-child(1) {
  border-bottom: 0px !important;
}

.for-contact p {
  font-size: 17px;
  font-weight: 400;
  color: black;
  text-align: center;
}

.congratulation-page {
  margin-top: 0px;
}

.congratulation-page .main_title {
  color: #04A7D0;
  font-size: 30px;
}

.congratulation-page .sub_title {
  color: #000;
  font-size: 18px;
  text-align: center;
}

.vChangeLabel {
  font: normal normal normal 14px/25px Poppins;
  letter-spacing: 0px;
  color: #8B8B8B;
}

.form-group-model .form-group {
  margin: 0px;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}

.modal-footer {
  border-top: none;
  padding-top: 0px;
  justify-content: center;
}

.modal-header .close {
  right: 0;
  padding: 0;
  margin: -0.6rem -0.7rem -1rem auto;
  width: 30px;
  height: 30px;
  background: #EE2F35 0% 0% no-repeat padding-box;
  position: absolute;
  top: -3px;
  border-radius: 50%;
  opacity: 1;
  z-index: 99999;
  color: #ffffff;
}

.model-header .close span {
  position: absolute;
  top: 7px;
  left: 13px;
  color: #fff;

}

#model_custome_css .modal-content .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  border-bottom: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

#model_custome_css .modal-content {
  position: relative;
  display: flex;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 70px #00000052;
  border: none;
}

#model_custome_css .modal-body.tandcpopup {
  height: 500px;
  overflow-y: scroll;
}

.bottom_contact.succs_contactus {
  margin-top: 30px;
}

@media (max-width: 576px) {
  .login-page .dark-bule-bg:before {
    width: 100%;
  }

  .cmfont {
    font-size: 12px;
  }
}

.expired {
  background-color: #cf1515;
}

.green-tick {
  color: #14CC5E;
  font-size: 20px;
}

.status img {
  padding-bottom: 4px;
  margin-left: -3px;
}

.address-change-check-out {
  position: absolute;
  right: 12px;
  top: 0;
}

.address-change-check-out .fa-pencil {
  font-size: 24px;
  color: #ff9500 !important;
}

.renewal-period {
  background-color: #b3b3b357;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 16px;
}

.not-found {
  text-align: center;
  color: #ea4343;
  font-size: 20px;
}

/*-- Redeem-M-Coupon --*/
.rmc-heading {
  font: normal normal bold 26px/38px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.rmc-button .btn.disabled {
  background: #A8A8A8 0% 0% no-repeat padding-box;
}

.rmc-button .btn {
  font: normal normal 600 15px/35px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  padding: 3px 40px 3px 40px;
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.rmc-p {
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.redeem-m-coupon .form-group label {
  font-size: 16px;
  font-weight: 400;
}

.redeem-m-coupon .form-group .form-control {
  /* height: 50px; */
  background-color: #ffffff80;
  border: 0px;
  color: #000;
}

.redeem-m-coupon .form-group .form-control option {
  color: #000;
}

.redeem-m-coupon .accordion .collapsed {
  border: 0px;
}

.redeem-m-coupon .accordion .collapse {
  border-top: 1px solid #fff;
}

.redeem-m-coupon .accordion .scheme-box {
  border-radius: 6px;
}

.redeem-m-coupon .accordion .all-select .custom-control-label {
  font-weight: 500;
  font-size: 15px;
}

.redeem-m-coupon .accordion .all-select .custom-control-label::after {
  top: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  left: -2rem;
}

.redeem-m-coupon .accordion .all-select .custom-control-label::before {
  top: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  left: -2rem;
}

.redeem-m-coupon .accordion .custom-control-label {
  font-weight: 500;
  font-size: 20px;
}

.popup .form-group .form-control {
  color: #495057;
}

.redeem-m-coupon .accordion .scheme-box .custom-control-label::after {
  top: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  left: -2rem;
}

.redeem-m-coupon .accordion .scheme-box .custom-control-label::before {
  top: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  left: -2rem;
}

.border-b-dashed {
  /* border-bottom: 2px dashed #828282; */
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.scheme-box .coupon-box-label {
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
  white-space: nowrap;
}

.redeem-m-coupon .confirm-your-details {
  color: #000;
}

.redeem-m-coupon .confirm-your-details .scheme-box .address h6 {
  font-size: 20px;
}

.redeem-m-coupon .confirm-your-details .address-change {
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}

.redeem-m-coupon .confirm-your-details .scheme-box .address {
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 13px;
  padding: 15px 30px;
}

.redeem-m-coupon .confirm-your-details .scheme-box .address-title {
  font: normal normal bold 12px/35px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 0px !important;
}

.redeem-m-coupon .confirm-your-details .vendor-section {
  font-size: 22px;
}

.redeem-m-coupon .confirm-your-details .vandor-name {
  width: 65%;
  text-align: right;
}

.redeem-m-coupon .confirm-your-details .vendor-section .grey-label {
  color: #B1B1B1;
}

.redeem-m-coupon .save-btn {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 9px 35px;
  border-radius: 60px;
}

.address-change-modal .save-btn {
  font: normal normal 600 15px/25px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.address-change-modal .modal-header h4 {
  font: normal normal 600 22px/28px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.address-change-modal .modal-body form {
  color: #000;
}

.address-change-modal .modal-body form .custom-fctl {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
}

.address-change-modal .modal-body form .custom-fctl .css-yk16xz-control {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
  /* padding: 1px 8px; */
}

.address-change-modal .modal-body form .custom-fctl:disabled {
  background-color: #dbdbdb 0% 0% no-repeat padding-box !important;
}

.css-2b097c-container {
  padding: 0px;
}

.address-change-modal .modal-body form label {
  font: normal normal normal 12px/25px Poppins;
  letter-spacing: 0px;
  color: #8B8B8B;
}
.address-change-modal .modal-body form label small {
  font-size: 60%;
}

.LocalityHint {
  font: normal normal normal 8px/25px Poppins;
  letter-spacing: 0px;
  color: #8B8B8B;
}

.address-change-modal .modal-body form label sup {
  color: #FF1919;
  font-size: 12px;
  top: 0;
}

.address-change-modal .modal-header .close {
  right: 0;
  padding: 0;
  margin: -0.6rem -0.7rem -1rem auto;
  width: 30px;
  height: 30px;
  background: #EE2F35 0% 0% no-repeat padding-box;
  position: absolute;
  top: -3px;
  border-radius: 50%;
  opacity: 1;
  z-index: 99999;
  color: #ffffff;
}

.redeem-m-coupon .otp-container .otp {
  width: 60px;
}

.btn-light {
  background-color: #919191;
  border-color: #8e8b8b;
  color: #fff;
}

.change-vendor-details .form-group .form-control {
  background-color: #fff;
  color: #495057;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}

.error-msg .red-text {
  color: #FF3333;
}

.error-msg p {
  color: #000000;
}

/* .back-home {position: fixed;left: 202px;top: 113px;} */
.ansp {
  font-size: 16px;
  color: #474A4E;
  border-radius: 9px;
  text-align: right;
}

.ansp a:hover {
  text-decoration: none;
}

.ansp span {
  font-weight: 500;
  color: white;
}

.login-page .form-text h3 {
  font-size: 22px;
}

.login-page .form-text p {
  font-size: 15px;
  margin-top: 11px;
}

.footer .social-icon i {
  font-size: 22px;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
}

.footer .social-icon .fa-facebook {
  background-color: #3b5998;
}

.footer .social-icon .fa-twitter {
  background-color: #00acee;
}

.faq-footer .card-header .btn {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding-left: 9px;
}

.faq-footer .card {
  background-color: unset;
  border: 0px;
  border-bottom: 1px solid #fff !important;
}

.faq-footer .card .card-header {}

.faq-footer .btn-link:hover {
  text-decoration: none;
}

.faq-footer .btn-link.focus,
.btn-link:focus {
  text-decoration: unset;
}

@media (max-width: 576px) {

  .faq-footer .card-header .btn {
    padding-left: 5px;
    text-align: left;
  }

  /*-- Redeem-M-Coupon --*/
  .ansp {
    font-size: 14px;
    color: #474A4E;
    border-radius: 9px;
  }

  .w-75 {
    width: 100% !important;
  }

  /* .back-home {left: 8px!important;top: 95px!important;} */
  .redeem-m-coupon .confirm-your-details .address-change {
    font-size: 18px;
  }

  .redeem-m-coupon .confirm-your-details .scheme-box .address-title {
    font-size: 18px;
  }

  .redeem-m-coupon .confirm-your-details .scheme-box .address h6 {
    font-size: 18px;
  }

  .redeem-m-coupon .confirm-your-details .vendor-section {
    font-size: 18px;
  }

  .redeem-m-coupon .otp-container .otp {
    width: 45px;
  }

  .no-gutters {
    margin-right: -22px;
    margin-left: -22px
  }

  .successfully-text h3 {
    font-size: 22px;
  }

  .redeem-m-coupon .save-btn {
    font-size: 20px;
    padding: 5px 42px;
  }

  /*-- Redeem-M-Coupon End --*/
  /* .back-home {
      position: static;
      left: 8px!important;
      top: 95px!important;
    } */

  .popup_inner {
    left: 7% !important;
    right: 7% !important;
    overflow-y: auto;
  }

  .round label {
    left: -5px !important;
  }

  .bg-grey-mobile {
    background-color: #BEBEBE;
    width: 100%;
  }

  .login-page .pt-20 {
    padding-top: 0px;
  }

  .login-page .navbar-toggler {
    background-color: white;
    border: 1px solid transparent;
    border-radius: .25rem;
  }

  .login-page .navbar {
    z-index: 111;

    padding: 0px !important
  }

  .login-page .dark-bule-bg:before {
    width: 100%
  }

  .login-page .form-text h5:before {
    content: "";
    right: 83px;
  }

  .login-page .form-text h5:after {
    right: 70px;
  }

  .card-body .account-box .btn {
    padding: 5px 25px;
    font-size: 18px;
  }

  .valid .date {
    font-size: 13px;
  }

  .account-box img {
    display: block;
  }

  .checkout .ptp-btn {
    font-size: 16px;
  }

  .get-your-favourite h5 {
    font-size: 22px;
  }

  .card-header .btn {
    color: #ffffff;
    font-size: 16px;
  }

  .scheme-box .final-price h4 {
    font-size: 16px;
  }

  .scheme-box .cuting-price h4 {
    font-size: 16px;
  }

  .scheme-box .your-plan h4 {
    font-size: 16px;
  }

  .current-scheme {
    font-size: 12px;
    padding: 5px 6px;
  }

  .scheme-box .next-step a {
    margin-left: 15px;
    padding: 8px 14px;
  }

  .scheme-box .final-price h4 {
    margin-left: 0px;
  }

  /* Account Page */
  /* .bg-image {display:unset;} */

  .scroll {
    top: unset;
    left: unset;
    bottom: unset;
    right: unset;
    padding-right: 16px;
    overflow-y: unset;
    overflow-x: unset;
    max-height: unset;
  }

  /* Account Page End */

}


.panel-group {
  margin-top: 30px;
}


.panel-collapse {
  max-height: 500px;
  overflow: hidden;
  transition: max-height .5s ease;
}

.panel-close {
  max-height: 0;
}

/* .Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
} */

/* @media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
} */


.bg-image-Home {

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.btnDisabled {
  pointer-events: none;
  opacity: .65;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 111;
  overflow-y: scroll;
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  /* bottom: 25%; */
  margin: auto;
  background: white;
  color: #000;

}

.resnedOtp {
  text-align: left;
  font: normal normal normal 15px/45px Poppins;
  letter-spacing: 0px;
  color: #101820;
}

.vertical-text {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-rl;
  /* Makes text vertical */
  transform: rotate(180deg);
  /* Corrects text direction */
  align-self: flex-end;
  margin-right: -20px;
  height: 100%;
  z-index: 1;
}

.subscribenowtitle {
  white-space: nowrap;
  background: #04A7D0 0% 0% no-repeat padding-box;
  padding: 12px 8px;
  border-radius: 8px;
  margin: 0px;
  font: normal normal normal 13px / 25px Poppins;
}

.successP {
  text-align: center;
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
}

.faArrowLeft {
  color: black;
  font-size: 24px;
  margin-right: 13px;
  cursor: pointer;
}

.login-sidebar-main {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.incorrectOtp {
  /* float: right; */
  color: hsl(0, 100%, 50%);
  text-decoration: underline;
  font-weight: 400;
  font-size: 16px;
}


.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.Toastify__toast--info {
  background: #000000a6;
}

.Toastify__toast--warning {
  background: #000000a6;
}

.Toastify__toast--success {
  background: #000000a6;
}

.multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  /* Spacing */
  --rmsc-radius: 4px;
  /* Radius */
  --rmsc-h: 38px;
  /* Height */
}

.apply-promo-codes .custom-control-label {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.apply-promo-codes .custom-control {
  text-align: left;
  padding-left: 25px;
}

.promo-code-usage-text {
  color: cyan;
}

.buy {
  font-weight: 500;
  color: #ea0202;
  background: #ffffff;
  box-shadow: 0px 0px 16px #08528c;
  border-radius: 10px;
  border: 1px solid #08528c !important;
  transition: all 1s ease-in-out;
  padding: 5px 10px;
}

.btn-jittery {
  -webkit-animation: jittery 4s infinite;
  animation: jittery 4s infinite;
}

/* new corporate scheme CTA */
.subscription-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  margin-top: 15px;
  transition: all 0.5s ease;
  position: relative;
}

.subscription-card .brand-name {
  border-radius: 14px;
  cursor: pointer;
  width: 100%;
}

.description {
  font: normal normal normal 16px/55px Poppins;
  letter-spacing: 0px;
  color: #333333;
  margin: 10px 0;
}

.booking-button {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 21%;
  position: absolute;
  bottom: 0px;
  background: white;
  color: #ffffff;
  text-align: left;
  font-size: clamp(10px, 1.1vw, 32px);
  font-weight: 900;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background: url(https://images.livemint.com/subscription/img/button-gif.gif) no-repeat center center;
  background-size: 100%;
  border-radius: 0 0 14px 14px;
}

.booking-button:hover {
  background: #FA9D1C 0% 0% no-repeat padding-box;
  color: white;
}

.subscription-card, .booking-button {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.subscription-card:hover{
  transform: scale(1.05);
  box-shadow: 0 0 12px rgb(238 147 23);
}

.mint-bundle {
  position: absolute;
  bottom: -6px;
  z-index: 9;
  right: 14px;
  width: 43%;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.custom-modal-width .modal-dialog{
  max-width: 800px;
  width: 90%;
}

@keyframes jittery {

  5%,
  50% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9);
  }

  15% {
    transform: scale(1.15);
  }

  20% {
    transform: scale(1.15) rotate(-5deg);
  }

  25% {
    transform: scale(1.15) rotate(5deg);
  }

  30% {
    transform: scale(1.15) rotate(-3deg);
  }

  35% {
    transform: scale(1.15) rotate(2deg);
  }

  40% {
    transform: scale(1.15) rotate(0);
  }
}

@media (max-width: 576px) {
  .book-now {
    margin-right: 0%;
  }
}

@media (max-width: 768px) {
  /* .addressPopStyle {
    margin-top: 25% !important;
  } */
  .custom-modal-width .modal-dialog{
    max-width: unset;
    width: unset;
  }

  .booking-button {
    padding: 10px 10px;
  }

  .mint-bundle {
    bottom: 1px;
    z-index: 9;
    right: 9px;
    width: 44%;
  }

  .congratulation-page {
    margin-top: -4px !important;
  }

  .mt--20 {
    margin-top: -20px;
  }
  .bottom_contact .succs_contactus {
    margin-top: 20px;
  }

  /* .modal-header .close {
    right: 0;
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    width: 37px;
    height: 37px;
    background: #000;
    position: absolute;
    top: 3px;
    border-radius: 50%;
    opacity: 1;
    z-index: 99999;
  } */
  #model_custome_css.modal-dialog {
    margin: 1rem;
  }
  #model_custome_css .modal-body.tandcpopup {
    height: 500px;
    overflow-y: scroll;
  }

  .buy-now-cta {
    bottom: -28px;
    font: normal normal 600 13px/25px Poppins;
  }

}

.redeemCouponClr {
  color: rgb(20 128 196);
}


.blackClourV2 {
  color: black;
}

.leftTextAlign {
  text-align: left;
}

.dasboardBorder {
  border: 1px solid #e2e2e2 !important;
}

.successRenwed {
  /* padding: 15px 18px; */
  /* border-radius: 10px; */
  background-color: white;
}

.addressPopStyle {
  margin: 8rem auto;
}

.couponBlockdHide {
  font-weight: 500;
  font-size: 20px;
}

/* couponTabs-main start */

.couponTabs-main {
  color: #000000;
}

.couponTabs-main .MuiTabs-flexContainer {
  display: flex;
  border-bottom: 1px solid;
  justify-content: space-between;
}

.couponTabs-main .MuiTab-root {
  padding: 10px 10px 0px 0px;
  text-transform: capitalize;
}

/* .couponTabs-main .MuiTab-wrapper {
  flex-wrap: wrap;
  align-content: flex-start;
} */

.couponTabs-main .MuiBox-root {
  padding: 0px !important;
}

.couponTabs-main .custom-checkbox .custom-control-label::before {
  border-radius: unset;
}

.allreadyreleased .custom-control-label::before {
  content: none !important;
}

.disable-card {
  opacity: 0.3;
}

.couponCard {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding: 10px 15px;
}

.coupon-icon {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.coupon-icon img {
  padding: 5px 7px;
  margin-left: 5px;
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

.border-b-dashed .couponNo {
  margin-left: 5px;
  font: normal normal bold 14px/38px Poppins;
  letter-spacing: 0.42px;
  color: #000000;
}

.border-b-dashed .couponMonth{
  display: flex;
  font: normal normal normal 12px / 24px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  white-space: nowrap;
  background-color: #04A7D0;
  padding: 0px 10px;
  border-radius: 5px;
}

.coupon-box-label .separator {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.coupon-box-label .separator::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 12px;
  background-color: #000000;
  margin: 0 10px;
}

.couponDue {
  margin-right: 5px;
}

.CouponNotFound {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.CouponNotFound img {
  width: 250px;
}

.CouponNotFound h3 {
  color: #000000;
  text-align: center;
}


/* complaint-title css start here */
.complaint-title {
  font: normal normal bold 22px/38px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.form-complaint label {
  font: normal normal normal 12px/35px Poppins;
  letter-spacing: 0px;
  color: #8B8B8B;
}

.form-complaint input:focus,
input:not(:placeholder-shown) {
  color: black;
}

.form-complaint select option,
select:focus,
select[data-has-value="true"] {
  color: black;
}

.form-complaint select {
  height: 45px;
}

.button-complaint {
  display: flex;
  justify-content: center;
}

.form-complaint .btn {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal 600 12px/35px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  padding: 5px 30px;
}
.popupComplaint {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}
.popupMsgComplaint {
  font: normal normal 900 16px/28px Poppins;
  letter-spacing: 0px;
  color: #000000;
 }
.complaint_box_design {
  color: #000000;
  display: flex;
}
.complaint_box_design img {
  width: 500px;
}

.limited-text {
  display: inline-block;
  max-width: 120px;
  /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  text-transform: capitalize;
}

.summaryy-details-sec {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 13px;
  padding: 15px 15px 50px;
  position: relative;
}

.d-flex-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}

.scheme-box .confirm-btn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
}

.scheme-box .confirm-btn span {
  font: normal normal normal 9px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.send-otp-btn {
  display: flex;
  justify-content: center;
}

.send-otp-btn .btn {
  display: flex;
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  padding: 7px 35px;
  flex-wrap: wrap;
  align-items: center;
}

.redeem-m-otp {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}

.otp-mobile-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  color: black;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 15px;
}

.otp-mobile-text-OTP {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.otp-mobile-text-OTP label {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  font: normal normal bold 24px/48px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.otp-mobile-text-OTP label sup {
  top: 15px;
}

.otp-mobile-text {
  font: normal normal 600 12px/21px Poppins;
  letter-spacing: 0px;
  color: #333333;
}

.otp-mobile-text .mobile-no {
  font: normal normal 900 12px/21px Poppins;
}

.resend p {
  font: normal normal 600 12px/21px Poppins;
  letter-spacing: 0px;
  color: #333333;
}

.resend p a {
  text-decoration: underline;
  font: normal normal 600 12px/21px Poppins;
  color: #04A7D0;
}

.redeem-m-otp .save-btn {
  font: normal normal 600 15px/35px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  padding: 6px 30px;
}

.redeem-m-otp .otp-success-text {
  font: normal normal bold 24px/48px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
}

.nav-main .dropdown-item.active,
.dropdown-item:active {
  background: none;
  color: #000000;
}

.nav-main .dropdown-menu {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 60px #00000014;
  border: none;
}

.nav-main .dropdown-item a {
  display: flex;
}

.nav-main .img-avatar {
  border: 1px solid #EE2F35;
}

.all-faqs .MuiAccordion-root {
  /* padding: 10px 30px; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 12px 12px;
  margin-bottom: 10px;
  box-shadow: none;
}

.all-faqs .MuiAccordionSummary-content p {
  font: normal normal 600 16px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.all-faqs .MuiAccordionDetails-root p {
  font: normal normal normal 13px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.all-faqs .MuiAccordion-root:before {
  content: none;
}

.link-dark {
  font: normal normal 800 12px/45px Poppins;
  letter-spacing: 0px;
  color: #000000 !important;
}

.contact-us-row {
  padding-top: 100px;
  /* position: fixed; */
  /* bottom: 0; */
}

.contact-us {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 60px #00000014;
  border-radius: 40px 40px 0px 0px;
  padding: 20px 30px;
}

.contact-us .title,
.timings {
  display: flex;
  justify-content: center;
}

.contact-us .title h2 {
  font: normal normal bold 16px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
}

.contact-us .timings p {
  font: normal normal normal 13px/24px Poppins;
  letter-spacing: 0px;
  color: #858585;
}

.contact-us .details {
  display: flex;
}

.contact-us .details .dt-comman {
  padding: 15px;
}

.org-delhi {
  border-right: 1px solid #E8E8E8;
}

.dt-comman {
  /* display: flex; */
  flex-direction: column;
  flex-wrap: wrap;
  color: black;
  align-items: flex-start;
  justify-content: center;
}

.dt-comman .first {
  width: 100%;
  display: flex;
  align-items: center;
}

.dt-comman .first hr {
  width: 100%;
}

.dt-comman .first span {
  font: normal normal bold 16px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
}

.dt-comman .second div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.dt-comman .second a {
  text-decoration: underline;
  font: normal normal 600 13px/15px Poppins;
  letter-spacing: 0px;
  color: #1EB0D4;
  width: 100%;
  word-wrap: break-word;
}

.all-faqs .MuiAccordionSummary-content.Mui-expanded {
  margin: 7px 0;
}

.all-faqs .MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px;
}

.all-faqs .MuiIconButton-root {
  padding: 3px 12px;
}

.contect-us-icon {
  position: absolute;
  right: -56px;
  top: 20px;
}

.logedin-success {
  display: flex;
  justify-content: center;
}

.center-img img {
  width: 30%;
}
.contact_box_design .form-complaint .form-control {
  background: #FFFFFF;
  font: normal normal 600 12px / 35px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.contact_box_design .form-complaint .form-control:disabled {
  background-color: #e9ecef;
}

.order-1 {
  display: block;
  float: inline-end; /* .one is always first */
}

.order-2 {
  display: block;
  float: inline-start; /* Sidebar goes second on mobile */
}

.order-3 {
  display: block;
  float: inline-end; /* .two goes last on mobile */
}
.RedeemCoupon-tabsContainer-3 {
  z-index: unset;
}
.redeem-coupon-main {
  padding-top: 50px;
}
.addressPopStyle .inp-placeholder, .form-control, input::placeholder {
  font: normal normal 300 12px / 25px Poppins;
}
.addressPopStyle .otp-input, .form-control:disabled, .form-control[readonly] {
  font: normal normal 600 12px / 25px Poppins;
}
.profilecard {
  position: relative;
}
.profilecard .MuiAccordionSummary-root{
  padding: unset;
  cursor: default;
}
.profilecard .MuiAccordionSummary-content{
  flex-grow: unset;
  margin: unset;
  width: 100%;
}
.member-card {
  width: 100%;
}
.profilecard .MuiAccordionSummary-content.Mui-expanded {
  margin: unset;
  cursor: default;
}
.profilecard .MuiAccordionSummary-expandIcon{
  display: none;
  position: absolute;
  right: 10px;
  top: 0px;
}
.emailError{
  color: red;
  padding: 0px 110px;
}
/* New Media query for mobile devices */

@media (max-width: 768px) {
  /* Your CSS rules for small mobile devices */
  .emailError{
    padding: unset;
  }
  .side-img {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }
  .div-left-home-img {
    flex: none;
    height: 50%;
  }

  .div-left-side-img {
    flex: none;
    height: 50%;
  }

  .div-right {
    width: 90%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 30px 70px #00000014;
    border: 1px solid #04A7D02B;
    border-radius: 20px;
    position: absolute;
    top: 30%;
    flex: none; 
    overflow-y: unset;
    margin-bottom: 25px;
  }

  .pt-5-rem {
    padding-top: 4rem;
  }
  .pt-2-rem {
    padding-top: 1rem;
  }
  .main-first {
    padding-bottom: 50px;
  }

  .vertical-text {
    position: absolute;
    bottom: 35%;
    width: 80%;
    left: 10%;
    writing-mode: unset;
    transform: none;
    display: block;
    top:unset;
    height: auto;
  }
  .successPageSide .vertical-text {
    display: none;
    border: 1px solid #04A7D0;
    border-radius: 8px;
  }
  .successPageSide .intro-content{
    border: 1px solid #04A7D0;
    border-radius: 16px;
  }
  .successPageSide .successRenwed {
    border-radius: 10px;
  }
  .successPageSide .bottom-left {
    left: 0px;
  }
  .successPageSide .top-left {
    top: 10px;
  }
  .successPageSide .top-right {
    top: 10px;
  }
  .subscribenowtitle{
    font: normal normal normal 12px / 20px Poppins;
    white-space: unset;
    text-align: center;
  }

  .footer-main{
    display: none;
  }
  .RedeemCoupon-tabsContainer-3 {
    z-index: 1000;
  }
  .redeem-coupon-main {
    padding-top: unset;
  }
  .order-1 {
    margin-top: 25px;
  }
  .order-3 {
    margin-top: 30px;
  }

  .couponTabs-main .MuiTab-root{
    padding: unset;
  }
  .redeem-m-otp {
    margin-top: 30px;
  }
  .couponTabs-main {
    margin-bottom: 90px;
  }
  .couponTabs-main .MuiTabs-flexContainer {
    border-bottom: unset;
  }

  .contect-us-icon {
    right: 0px;
    top: -90px;
  }

  .popupComplaint {
    align-items: center;
    padding: 20px;
  }
  .complaint_box_design .popupImage {
    display: none;
  }
  #page-header-user-dropdown {
    margin-right: -26px;
  }

  .corporate-sidebar {
    height: 40%;
  }
}

input, textarea {
  outline: none;
  background-clip: padding-box;
  box-shadow: none;
}
input, textarea {
  user-select: auto;           /* Standard */
  -webkit-user-select: auto;   /* Safari and older iOS versions */
  -moz-user-select: auto;      /* Older Firefox versions */
  -ms-user-select: auto;       /* Internet Explorer and Edge (legacy) */
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

.corporate-offers {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
}

.ht-info-badge{
  background: #FCEBD3 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
  text-align: center;
  font: normal normal bold 13px/20px Poppins;
  letter-spacing: 0px;
  color: #444444;
  padding: 10px 15px;
  margin-bottom: 30px;
}

.offer-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;

}

.offer-card {
  position: relative;
  display: flex;
  flex-direction: column; /* Align children vertically */
  justify-content: space-between; /* Push the 'p' tag to the bottom */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: calc(33.33% - 15px); /* 3 cards per row with gap */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-top: 20px;
}

.offer-card .logo {
  width: 100%;
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 60px #00000029;
  border-radius: 8px;
  padding: 8px 13px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.img-widht-10{
  max-width: 10%;
}

.img-widht-12{
  max-width: 12%;
}

.bg-corporate {
  background: linear-gradient(90deg, #e5ebff, #feebd1 35%, #fbe9e9);
}

.offer-card .cost {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}
.offer-card .cost .price{
  font: normal normal bold 18px/25px Poppins;
}
.offer-card .cost .time{
  font: normal normal normal 14px/25px Poppins;
}

.offer-card .btn {
  bottom: 0;
  left: 0;
  width: 100%;
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: none;
  font: normal normal bold 9px/14px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  position: relative;
  padding: 10px;
}

/* Corporate side bar */

.corporate-sidebar {
  background: rgb(249,157,28);
  background: linear-gradient(180deg, rgba(249,157,28,1) 0%, rgba(209,115,7,1) 100%);
}
.corporate-sidebar .login-sidebar-main{
  justify-content: center;
}
.corporate-sidebar .corporate-sidebar-main{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.corporate-sidebar .corporate-sidebar-main h1{
  font: normal normal normal 18px/25px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
}
.corporate-sidebar .corporate-sidebar-main img{
  height: 110px;
}
.corporate-active .bottom-left {
  left: 0;
}

.offer-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  align-items: stretch;
  gap: 22px;
}
.new-offer-logo {
  width: 85%;
}
.corporate-otp-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.corporate-title {
  font: normal normal bold 24px/35px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.corporate-info {
  font: normal normal bold 13px/20px Poppins;
  letter-spacing: 0px;
  color: #444444;
  background: #FCEBD3 0% 0% no-repeat padding-box;
  border-radius: 9px;
  padding: 10px 20px;
}
input[readonly] {
  background-color: #f5f5f5 !important;
  border: 1px solid #ccc;   
  color: #555 !important;             
  cursor: not-allowed;     
  pointer-events: none;    
}

.order-input.valid-tick {
  flex: 1; /* Ensure input takes up remaining space */
  border: 2px solid #07b107 !important;
}

.input-tick {
  margin-left: 8px; /* Space between input and tick */
  color: #28a745; /* Green color for the tick */
  font-size: 18px; /* Adjust size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

