.topNavigtion{
  margin-top: -10px;
}

.flag
{
    width: 25px;
}

.formV2 {
  position: relative;
  /* box-shadow: 0 10px 10px rgb(0 0 0 / 20%); */
  box-sizing: border-box;
  padding: 12px 25px;
  /* background-color: #f8f9fa; */
  /* border-radius: 15px; */
  /* border: 1px solid #04A7D0; */
  margin-left: auto;
  margin-right: auto;
  /* width: 61%; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 30px 70px #00000014;
  border: 1px solid #04A7D02B;
  border-radius: 20px;

  }

  .mainheadingV2{

    visibility: visible;
 animation-duration: 0.2s;
 animation-delay: 0.2s;
 animation-name: fadeInDown;
 
}


.subheadingV2{
visibility: visible;
     animation-duration: 0.5s;
     animation-delay: 0.5s;
     animation-name: fadeInDown;
}

.paragraphV2{
visibility: visible;
     animation-duration: 0.8s;
     animation-delay: 0.8s;
     animation-name: fadeInDown;
}

.blackClourV2{
    color: black;
  }

.control-labelV2, .clickV2{
    display: inline-block;
    vertical-align: middle;
    margin: 10px 0;
    color:#1480C4;
  }

  .control-labelV2{
      margin-top: 14px;
     
  }
  
  .clickV2
  {
      background-color: #fff;
      border: 2px solid #1480C4;
      border-radius: 5px;
      padding: 2px 15px;
  }
  .inp-placeholder, .form-control, input::placeholder {
    text-align: left;
    font: normal normal normal 12px / 28px Poppins;
    letter-spacing: 0px;
    color: #8B8B8B;
    height: auto;
  }
  .labelFontSize1V1 {
    font: normal normal bold 28px/55px Poppins;
    letter-spacing: 0px;
    color: #000000;
  }
  
  .playStore{
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .playStore a {
    display: contents;
  }
  .playStore a img {
    width: 30%;
    padding: 10px;
  }

  .login-next-btn{
    background: #04A7D0 0% 0% no-repeat padding-box;
    border-radius: 8px;
  }

  .bg-white{
    background-color: #fff;
  }

  .clickV2-alink
  {
    background: transparent linear-gradient(92deg, #FFFFFF 0%, #F0B80B 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 70px #00000029;
    border: 1px solid #D8D8D8;
    border-radius: 7px;
    font: normal normal normal 14px/22px Poppins;
    letter-spacing: 0px;
    padding: 7px 8px;
    color: #000000;
    text-transform: uppercase;
  }
  .clickV2-alink:hover {
    color: #000000;
    text-decoration: none;
  }
  .productImgV2
  {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .getOtpBtn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  .setOtpBtn{
    justify-content: center;
  }

  .login-btnV2 {
      font: normal normal 600 13px/25px Poppins;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-transform: uppercase;
      cursor: pointer;
      padding: 7px 42px;
      border: none;
      transition: all 0.5s;
      /* border-radius: 59px; */
      position: relative;
      min-width: 120px;
      margin: auto;
      display: block;
      background: #04A7D0 0% 0% no-repeat padding-box;
      border-radius: 8px;
  }

 .login-btnV2.btn:hover{
   color:#fff;
   border:none;
}

.footer-main{
  background: #000 0 0 no-repeat padding-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.footer-font-left {
  font: normal normal bold 18px/28px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  cursor: pointer;
}
.footer-font-right {
  font: normal normal 600 12px/25px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}

.dislogin-btnV2 {
    font-family: muli;
     background: #908d8d;
     color: #ffffff;
     cursor: pointer;
     font-size: 20px;
     padding: 5px 15px;
     border: 0;
     transition: all 0.5s;
     border-radius: 10px;
     position: relative;
     min-width: 120px;
     margin: auto;
     display: block;
     color:#fff;
     border:none;   
     pointer-events: none;
     /* background-color: rgb(238, 47, 53); */
 }

 .dislogin-btnV2 .btn:hover{
    color:#fff;
     border:none;
 }

 .promptV2 {
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: 8px;
    color: #000;
  }

  .otp-error{
    padding: 10px 20px;
    box-shadow: lightgray 5px 3px 5px;
    border-radius: 5px;
    border: 1px solid;
    font-size: 13px;

  }
  
    .mob-nj{
      text-align: left;
      font: normal normal 900 28px/35px Poppins;
      letter-spacing: 0px;
      color: #000000;
    }
    .otp-input{
      background: #FFFFFF 0% 0% no-repeat padding-box !important;
      border: 1px solid #CDCDCD;
      border-radius: 8px;
      height: auto;
      text-align: left;
      font: normal normal 600 14px/45px Poppins;
      letter-spacing: 0px;
      color: #000000;

    }
    .otp-input, .form-control:disabled, .form-control[readonly]{
      background: #e9ecef 0% 0% no-repeat padding-box !important;
      font: normal normal 600 12px/28px Poppins;
      letter-spacing: 0px;
      color: #000000;
    }
    .input-group-text{
      background-color: #ffffff;
    }
    .otpboxV2{
        display: flex;
        justify-content: center;
        height: 20px;
    }
    

  
 
  .order-infoV2 {
    padding: 20px 0px;
    position: relative;
    width: 100%;
  }

  .order {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 100%;
    overflow: hidden;
    width: 700px;
    margin: auto;
  }
  
  .order h6 {
  
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .order h2 {
    letter-spacing: 1px;
    margin: 10px 0;
  }
  
  .order-preview {
    background-color: #303030;
    color: #fff;
    padding: 30px;
  }
  
  .order-preview a {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    opacity: 0.6;
    margin-top: 30px;
    text-decoration: none;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    background-color: #9ACD66;
    border-radius: 50%;
    display: inline-block;
  }

  .order-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.order__button {
    border: none;
    /* font-family: "Quicksand", sans-serif; */
    font-weight: 400;
    font-size: 13px;
    margin: 0px 8px;
    padding: 0px 0px;
    width: 115px;
    border-radius: 20px;
    height: 28px;
    color: #fff;
    cursor: pointer;
    backface-visibility: hidden;
    transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .playStore{
    display: flex;
  }
  .preauthbackimg{
    width: 70% !important;
  }

  .preauthbacktxt{
    text-align: center;
    font-size: 14px !important;
  }

    .callus{
        font-size: 10px;
    }

  .order__button {
        margin: 5px;
        width:56%;
        margin-left:19%;
  }
 .order-button{
    display: block;
  }

  .absolute-center{
    padding-top: 20% !important;
  }
  .order{
    max-width: 80%;
  }
  .otpboxV2 .otp-input-b input { 
    width: 4em !important;
  }

  .formV2 {
    box-shadow: none;
    padding: 0px;
    background: unset;
    border-radius: unset;
    border: none;
  }

  .mob-nj {
    font: normal normal 900 22px / 35px Poppins;
  }

  .playStore a img {
    width: 50%;
  }

}
@media screen and (max-width: 576px) {
  
  .preauthbacktxt{
    text-align: center;
    font-size: 12px;
  }
 
  .order__button:last-child {
    margin-bottom: 0;
  }
}
.order__button:focus {
  outline: none !important;
}


.order__button:last-child {
  margin-right: 0;
}
.order__button.button--blue {
  background: linear-gradient(45deg, #1da1f2, #0e71c8);
  box-shadow: 0px 4px 30px rgba(19, 127, 212, 0.4);
}
.order__button.button--blue:hover {
  box-shadow: 0px 7px 30px rgba(19, 127, 212, 0.75);
}
.order__button.button--orange {
  background: linear-gradient(45deg, #69df36, #066b0f);
  box-shadow: 0px 4px 30px rgba(223, 45, 70, 0.35);
}
.order__button.button--orange:hover {
  box-shadow: 0px 7px 30px rgba(12, 14, 13, 0.75);
}

.refer-Card  {
    border-radius:10px;
    }
    .refer-Card:hover .card-header, .refer-Card:hover .card-footer {
      color: #ba8b00;
      border-color: #ffc107;
      background-color: #ffeeba;
    }
    .refer-Card:hover .promotion-promo {
      transform: scale(1.175) translateY(2.5px);
      transform-origin: left center;
    }
    .refer-Card:hover .promotion-price {
      transform: translate(2.5px, 17.5px) scale(1.15);
      transform-origin: center right;
    }
    
    .referral{
        padding-bottom:2em;
    }
    .card .btn {
      text-transform: uppercase;
    }


    .card-animate {
        counter-reset: section;
      }
      .card-animate .card-body:before {
        transition: 0.5s ease;
        counter-increment: section;
        content: "" counter(section) "";
        display: block;
        font-size: 15rem;
        font-weight: 900;
        position: absolute;
        bottom: 5rem;
        line-height: 0;
        left: -0.85rem;
        padding: 0;
        margin: 0;
        color: rgba(0, 0, 0, 0.1);
        z-index: 0;
      }
      .card-animate .card:hover .card-body:before {
        transform: translate(10px, -10px);
      }
      .card-animate .card-text {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
      
      .card-animate .card-title {
        font-weight: 900;
        text-transform: uppercase;
      }


      .accordionV2 .card-headerV2:after {
        font-family: 'FontAwesome';  
        content: "\f068";
        float: right; 
        color: #1480C4;
        }

        .accordionV2 .card-headerV2.collapsed:after {
          /* symbol for "collapsed" panels */
          content: "\f067"; 
          }

          .card-headerV2{
            background-color: #fff;
            }
            .card-titleV2{
            color: #1480C4 !important;
            font-weight: 500;
            }

            .publicationV2{
              margin-left: auto;
              border: 1px solid #ccc;
              display: block;
              background-color: #fff;
              padding: 3px 10px;
              }

              .active-couponV2 {
                /* background-image: url(images/active.png); */
                background-repeat: no-repeat;
                background-size: 100%;
                width: 100%;
                height: 140px;
            }
  .fontNJ {
    font-family: 'Poppins'
  }

.incorrectOtp1 {
    color: hsl(0, 100%, 50%);
    text-decoration: none !important;
    font-weight: 400;
    font-size: 16px;
    position: absolute;
    left: 170px;
  }

  .loadder{
    margin-top: 50px;
    /* margin: auto; */
    padding: 24px;
    margin-left: -3px;
  }

  .absolute-center{
    padding-top: 7%;
  }


  .preauthbackimg{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 86px;
    width: 40%;
  }

  .preauthbacktxt{
    text-align: center;
    font-size: 24px;
  }


  /* Digital coupons css start */
  .pub-plan-sec {
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    padding: 10px 0px;
  }
  .pub-plan-sec.redeem-back-one {
    background-image: url('../images/Orders/redeem-order.png');
  }
  .pub-plan-sec.redeem-back-two {
    background-image: url('../images/Orders/redeem-order-2.png');
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .couponTabs-main .PrivateTabIndicator-colorSecondary-9{
    background-color: #04A7D0;
  }

  .pub-plan-label {
      background: #F9F9F9 0% 0% no-repeat padding-box;
      border-radius: 8px;
      font: normal normal 600 10px / 26px Poppins;
      letter-spacing: 0px;
      color: #71352B;
      text-transform: uppercase;
      border: none;
      padding: 0px 8px;
  }
  .noMatchingOrder {
    height: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    color: #ffffff;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .noMatchingOrder p {
    margin-bottom: 0px;
  }
  .CouponNotFound .order-alink {
    text-decoration: underline;
    font-weight: 900;
    margin-left: 5px;
    color: #000000;
  }
  .pub-plan-sec .separator::before {
    background-color: #ffffff !important;
  }

  .pub-plan-sub-sec {
    display: flex !important;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
  .pub-plan-sub-sec-font {
    font: normal normal 900 15px/28px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

  .pub-plan-sub-sec-font-2 {
    font: normal normal 800 12px/28px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
  
  .separator {
    display: flex;
  }
  .separator::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 15px; /* Adjust height as needed */
    background-color: #ffffff; /* Adjust color as needed */
    margin: 0 10px; /* Adjust spacing as needed */
  }

  .pub-plan-btn-div {
    height: 100%;
    display: flex !important;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
  }
  .pub-plan-btn {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border-radius: 8px;
    font: normal normal 600 12px/36px Poppins;
    letter-spacing: 0px;
    color: #71352B;
    text-transform: uppercase;
    border: none;
    padding: 5px 15px;
  }
  .renew-btn {
    background: transparent linear-gradient(91deg, #F0B80B 0%, #F9D66A 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #00000054;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
  }
  .renew-btn .fa-refresh {
    margin-right: 10px;
    font-size: medium;
  }
  .pub-plan-btn .form-control:focus {
    box-shadow: unset;
  }
  .error-title {
    font: normal normal bold 28px/55px Poppins;
    letter-spacing: 0px;
    color: #000000;
  }
  .error-sub-title {
    font: normal normal normal 12px/24px Poppins;
    letter-spacing: 0px;
    color: #000000;
  }

  .error-msg {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .bpo-call-detail {
    border: 1px solid #D8D8D8;
    color: #000000;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 361px;
    height: 48px;
  }

  .bpo-call-detail a {
    color: #04A7D0;
  }

  @media (max-width: 768px) {
    .pub-plan-sec {
      margin-bottom: unset;
    }
    .pub-plan-btn {
      font: normal normal 600 10px/36px Poppins;
      padding: 4px 8px;
    }
    .couponTabs-main .card-body{
      padding: unset;
    }

    .couponTabs-main .PrivateTabIndicator-root-69{
      background-color: unset !important;
    }
    .couponTabs-main .MuiTabs-scroller{
      padding: 8px 8px 0px 23px;
      background: #04A7D0;
      color: white;
    }
    .error-msg {
      padding: 0px 10px;
      align-items: center;
    }
    .error-title {
      font: normal normal bold 24px/55px Poppins;
      text-align: center;
    }
    .error-sub-title {
      font: normal normal normal 13px/24px Poppins;
      padding: 0px 10px;
      text-align: center;
    }
    .bpo-call-detail {
      height: unset;
      width: unset;
    }

  }

  input, textarea {
    outline: none;
    background-clip: padding-box;
    box-shadow: none;
  }
  input, textarea {
    user-select: auto;           /* Standard */
    -webkit-user-select: auto;   /* Safari and older iOS versions */
    -moz-user-select: auto;      /* Older Firefox versions */
    -ms-user-select: auto;       /* Internet Explorer and Edge (legacy) */
  }
  input[type="checkbox"] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
  }