.complaint-management .card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B8B8B8;
  border-radius: 10px;
  color: #000000;
  /* cursor: pointer; */
}

.complaint-management .first {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-start;
}
.complaint-management .first .card-title {
  font: normal normal bold 15px/18px Poppins;
  letter-spacing: 0px;
  color: #313131;
  max-width: 60%;
  margin-bottom: 20px;
}

.complaint-management .first .btn {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font: normal normal 900 9px/16px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: default;
}
.complaint-management .first .newCase {
  background: #04A7D0 0% 0% no-repeat padding-box;
}
.complaint-management .first .resolved {
  background: #EE974C 0% 0% no-repeat padding-box;
}
.complaint-management .first .closed {
  background: #2DA67A 0% 0% no-repeat padding-box;
}
.complaint-management .first .in-progress {
  background: #ee2f35 0% 0% no-repeat padding-box;
}

.complaint-management .second {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.complaint-management .second span {
  font: normal normal 400 12px/18px Poppins;
  letter-spacing: 0px;
  color: #313131;
}
.complaint-management .third {
  margin-top: 10px;
}
.complaint-management .third p {
  font: normal normal 300 10px/16px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.complaint-management .third .descTitle {
  font: normal normal bold 12px/38px Poppins;
  letter-spacing: 0px;
  color: #313131;
}

.order-select .btn {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 5px 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  font: normal normal 600 12px / 35px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
}
/* .complaint-management Details Popup CSS */

#complaint-details .card {
  border: none;
  cursor: unset;
  border-radius: unset;
}
#complaint-details .card-body {
  padding-top: 0px;
  max-height: 450px;
  overflow-y: overlay;
}
#complaint-details .description-divider {
  border: 1px solid #EAEAEA;
}

#complaint-details .complaintDesc {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: #E7FAFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 10px 15px;
  margin-top: 10px;
}

#complaint-details .complaintDesc .dateTime{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

/* CI-details CSS */
#CI-details .modal-content {
  padding: 5px 10px;
  color: #000000;
}
#CI-details .CITitle{
  font: normal normal 600 20px/28px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
#CI-details .card {
  border: none;
}
#CI-details .card .first {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
#CI-details .card .first .card-title {
  font: normal normal bold 15px/38px Poppins;
  letter-spacing: 0px;
  color: #313131;
  margin-bottom: 0px;
}

#CI-details .card .first span {
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
}