
/* .breadcrumbs {
  padding: 10px 0;
} */
 .order-body {
  margin-bottom: 10px;
 }

.breadcrumb {
  display: flex;
  list-style: none;
  background: none;
  padding: 0;
  margin: 0;
  font: normal normal 600 12px / 21px Poppins;
  letter-spacing: 0px;
  color: #333333;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  padding: 4px;
  color: #6c757d; /* Adjust the color if necessary */
}

.breadcrumb-item a {
  text-decoration: none;
  color: #007bff; /* Adjust the color if necessary */
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item.active {
  color: #6c757d; /* Adjust the color if necessary */
}

.order-title{
  color: black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
}
.order-back-title {
  font: normal normal bold 24px/48px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.order-back-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.order-select .form-select {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-radius: 8px;
  padding: 10px 5px;
  width: 159px;
  height: 45px;
}

.active-order {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 70px #00000021;
  border: 1px solid #B8F1FF;
  border-radius: 10px;
  padding: 18px;
  color: black;
}
.order-first-first {
  
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: baseline;
}

.order-first-first:first-child{
  font: normal normal bold 15px/28px Poppins;
  letter-spacing: 0px;
  color: #313131;
}
.order-first-first .last{
  font: normal normal 900 9px/19px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 23px;
}

.order-first div{
  color: black;
}

/* .greenDot {
  background: #2DA67A 0% 0% no-repeat padding-box;
} */
/* .Dot::before { */
  /* content: '';
  display: inline-block;
  width: 10px;
  height: 10px; */
  /* background: #2DA67A 0% 0% no-repeat padding-box; */
  /* border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle; */
/* } */

.Dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}

.greenDot {
  background: #4FDC6B 0% 0% no-repeat padding-box;
}
.LiveDot {
  background: #4FDC6B 0% 0% no-repeat padding-box;
}
.ExpiredDot {
  background: #EE2F35 0% 0% no-repeat padding-box;
}
.DraftDot {
  background: #EE974C 0% 0% no-repeat padding-box;
}
.TerminatedDot {
  background: #04A7D0 0% 0% no-repeat padding-box;
}

.separator::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 11px;
  background-color: #000000;
  margin: 0 5px;
}

.order-first-second {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font: normal normal bold 12px / 28px Poppins;
  letter-spacing: 0px;
}

.order-first-second .fSec-head {
  font: normal normal normal 12px/28px Poppins;
  letter-spacing: 0px;
  color: #313131;
  margin-right: 3px;
}
.order-first-second .fSec-title {
  font: normal normal 600 12px/28px Poppins;
  letter-spacing: 0px;
  color: #313131;
}

.order-first-third { 
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.order-first-third .btn {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: normal normal normal 12px/28px Poppins;
  letter-spacing: 0px;
  color: #F8F8F8;
  padding: 5px 35px;
}

/* Second section of active order */

.order-second {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}
.order-second div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.order-second .text {
  font: normal normal 300 9px/13px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.order-second .active .text {
  font: normal normal 600 9px/13px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.order-second .img {
  width: 26px;
  height: 26px;
  background: transparent 0% 0% no-repeat padding-box;
}

.all-orders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0px;
}
.order-item {
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  /* border: 1px solid #B8B8B8 !important; */
  /* border-radius: 10px !important; */
  /* padding: 15px !important; */
  box-shadow: none !important;
}
.order-management .card{
  border: none !important;
}
.order-management .order-first-first .last {
  color: #000000;
}
.order-management .type {
  float: right;
}
.order-management .order-first-first {
  padding-left: unset;
}
.order-management .order-first-second .fSec-title, .fSec-head {
  font: normal normal 600 11px / 28px Poppins;
}
.all-orders .MuiAccordionSummary-root {
  padding: 0px;
}

.all-orders .MuiAccordionSummary-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.all-orders .MuiAccordionSummary-expandIcon {
  position: absolute;
  top: 0;
  right: 10px;
  transform: none !important;
}

.all-orders .orderExpandIcon{
  display: flex;
  align-items: center;
}
.all-orders .orderExpandIcon .MuiTypography-body1 { 
  font: normal normal bold 13px/48px Poppins;
  letter-spacing: 0px;
  color: #313131;
  text-transform: uppercase;
  margin-right: 8px;
  line-height: 1;
}

.all-orders .MuiAccordionDetails-root {
  display: flex;
  padding: 0px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.all-orders .MuiAccordionActions-root {
  padding: 20px 15px 0px;
  justify-content: center;
}
.order-second-first {
  position: relative;
}
/* Tooltip styles */
.tooltip-content {
  background: #1480c4;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 13px;
  /* max-width: 200px; Limits width for longer content */
  white-space: nowrap; /* Prevents text from wrapping */
  text-align: center;
  position: relative;
}

.tooltip-content::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  bottom: -5px; /* Adjust this value for better positioning */
  transform: translateX(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #1480c4; /* Same color as tooltip background */
}


@media only screen and (max-width : 768px) {
  .order-second .text {
    width: 95%;
    text-align: center;
  }
  .tooltip-content {
    max-width: 145px;
    white-space: unset;
  }
  .order-second-first {
    width: 25%;
  }
  .order-back-title {
    font: normal normal bold 20px / 48px Poppins;
  }
}