.NavBarNaviation{
    font: normal normal 600 12px / 35px Poppins;
    /* height: 60px;
    width: 100%;
    position: fixed;
    z-index: 999;
    opacity: 0.9;
    display: flex;
    justify-content: center;
    align-items: center; */
 }


 .navigation-wrap{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    -webkit-transition: all 0.3s ease-out;
    padding: 7px 16px;
    box-shadow: 0 10px 30px 0 rgb(138 155 165 / 15%)
   
 }

.NavBar nav ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.NavBar nav ul li{
    display: inline-block;
    margin: 1.5vw;
}

.NavBar a:hover {
    color: red;
}

.NavBar nav ul li a{
   text-decoration: none !important;
   color: black !important;
}


 .NavBar a:active {
    color: red !important;
  }
.MobileNavigation{
    display: none;
}

@media (max-width:750px) {

     .Navigation{
        display: none;
    }

     .MobileNavigation{
        display: flex;
    }

    .MobileNavigation .Hamburger{
        position: absolute;
        right: 0;
        cursor: pointer;

    }

    .NavBar nav ul{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 69px;
        right: 0;
        width: 100%;
        background: rgb(248 249 250);
              
    }

    .stop-scroll {
        margin: 0;
        height: 100%;
        overflow: hidden;
      }
}
