.orderCh {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    margin: auto;
    padding:28px;
    }
    .current-schemeCh {
      background: transparent url('../images/Orders/checkout-bg.png') 0% 0% no-repeat padding-box;
      background-size: cover;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    .current-schemeCh, .other-scheme{
      border-radius: 10px;
      padding: 15px;
    }
    .other-scheme p
    {
    font-size: 10px;
    font-weight: 400;
    color: #606060;
    }
    /* .order-details{
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 13px;
      padding: 15px;
    } */
    .current-schemeCh .checkout-left-first p{
      font: normal normal 10px/28px Poppins;
      letter-spacing: 0px;
      color: #FFFFFF;
    }
    .current-schemeCh .checkout-left-second p{
      font: normal normal 10px/28px Poppins;
      letter-spacing: 0px;
      color: #000000;
    }
    .order-details p{
      font: normal normal normal 13px/28px Poppins;
      letter-spacing: 0px;
      color: #000000;
    }
    .current-schemeCh h6{
      font: normal normal bold 18px/28px Poppins;
      letter-spacing: 0px;
      color: #FFFFFF;
      margin: 0px;
    }
    .order-details h5{
      font: normal normal bold 18px/38px Poppins;
      letter-spacing: 0px;
      color: #000000;
    }
    .scheme-tag{
    float:left;
    }
    .coupon{
    font-size:13px;
    }
    .apply{
    background-color:#1480C4 !important;
    color:#fff;
    }
    .total-price{
    background-color: #D1EDF5;
    border-radius: 0px 0px 10px 10px;
    }
    .total-price p{
    padding:12px; 
  font-size: 16px;
   font-weight: 700;
    }
  .checkout {
    padding: 10px 40px;
    position: absolute;
    bottom: -32px;
    left: 73px;
  }
  .checkout-btn{
    background: #04A7D0 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 6px;
    width: 100%;
    color:#fff;
    padding: 10px 90px;
  }

  .checkout-btn:hover{color: #fff;}
  .select-btn:hover{color: #fff;}




    .header__center {
    font-size: 12px;
    font-weight: 500;
    display: grid;
    color:#04A7D0;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.2rem;
    align-items: center;
    }
    .header__center::before,
    .header__center::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #04A7D0;
    }
    .benefits-grid {
    padding: 1rem;
    max-width: 1200px;
    margin: auto;
    }
    @media (min-width: 900px) {
    .benefits-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    }
    }
    .benefits-card {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
    margin: 1rem 0;
    }
    @media (min-width: 900px) {
    .benefits-card {
    margin: 0;
    }
    }
    .benefits-icon {  
    color: #fff;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    }
    @media (min-width: 700px) {
    .benefits-icon {
    width: 15px;
    height: 15px;
    }
    }
    @media (min-width: 700px) {
    .benefits-icon i {
    font-size: 9px;
    }
    }
    .benefits-icon.audio {
    background-color: #04A7D0;
    }
    .benefits-text p {
    font-size: .6rem;
    line-height: 1.3;
    }

    /* @media (min-width: 700px) {
    font-size: 1rem;
    } */

    .Row {
    display: table;
    width: 100%; /*Optional*/
    table-layout: fixed; /*Optional*/
    border-spacing: 10px; /*Optional*/
    }
    .Column {
    display: table-cell;
    }
    .digital-text{
    font-size:10px !important;
    }
    .switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
    .switchToggle label {cursor: pointer; text-indent: -9999px;width: 60px;
    max-width: 70px;
    height: 25px; background:#D1EDF5;  border-radius: 100px; position: relative; }
    .switchToggle label:after {content: ''; position: absolute; top: 2px; left: 4px;
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
    border: 7px solid #6f6f6f; }
    /* .switchToggle input:checked + label, .switchToggle input:checked + input + label  { } */
    .switchToggle input + label:before, .switchToggle input + input + label:before {position: absolute; top: 5px; left: 35px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
    .switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {position: absolute; top: 5px; left: 10px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
    .switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); 
    border: 7px solid #66C905;
    }
    .switchToggle label:active:after {width: 60px; } 
    .toggle-switchArea { margin: 10px 0 10px 0; }
    .offers-box {margin-top: 20px!important;display: block;text-align:center;}
    .offers-box {box-shadow: rgb(0 0 0 / 16%) 3px 3px 7px; padding: 10px;
    border-radius: 10px; margin-top: 30px; height: 100%;
    min-height: 100%;}
    .offers-box h5 {font-size: 15px;}
    .offers-box p {font-size: 13px;line-height:16px;} 
    .offers-box a {color:#000;}

    .modal-dialog{
      transition: transform .3s ease-out;
      transform: translate(0, -50px);
    }
    
    #duration-box p{
      /* text-align: right; */
      margin: 0px;
    }
    .free
    {
    color: #66C905;
    }
    .table td, .table th{
    padding:.35rem;
    border:none;
    }
    
    .card-box .price-box { background-color: #3B3B3B;padding: 10px;border-bottom: 1px solid #696969;}
    .card-box .price-box span {    font-size: 18px;
font-weight: 400;
color: #fff;
margin-left: 30px;}
    .language-select .form-control {color: #ffffff;background-color: transparent;border: unset;}
    .language-select .form-control option {    color: black;}
    .language-select .form-control:focus { box-shadow: unset;}
    .card-payment form {padding: 20px;}
    .card-payment form  .card-details .form-control{font-size: 22px;font-family: Century751 SeBd BT;border: 0px;border-bottom: 1px solid #707070;border-radius: 0;margin-bottom: 20px;}
    .card-payment form  .card-details .form-control:focus {box-shadow: unset;}
    .card-payment .pay {background-color: #1E82CF;color: #fff;text-align: center; padding: 8px;}
    .card-payment .pay a {color: #fff;font-size: 20px;}
    .payment-method {padding: 9px 20px;}
    .payment-method p a .fa {font-size: 25px; color: #000;}
    .payment-method p a span {font-size: 20px;color: #000;}
    .payment-method .number {font-size: 26px;}
    .card-details label {
    display: inline-block;
    margin-bottom: 0;
    font-size: 22px;
    font-family: Century751 SeBd BT;
    color: #B4B4B4;}
    .number-change-section .card-details .form-control {
    font-size: 20px!important;
    font-family: 'Poppins'!important;
    }

    .method-detail{
       font-size:15px;
       color:#868686;
    }

    .method-type{
    font-size: 17px;
color: #464646;
    }

    /*Filter Css*/
.filters {
width: 100%;
text-align: center;
}

.filter-ul h4{
text-align: right;
}

.filters ul {
list-style: none;
padding: 5px 0;
}

.filter-ul li {
display: inline;
padding: 5px 16px;
font-size: 10px;
color: #636363;
font-weight: 400;
cursor: pointer;
text-transform: uppercase;
letter-spacing: .2em;
}

.filter-ul li:hover {
color: #a6a6a6;
}

.filter-ul li.active {
color: #878787;
border: 1px solid #ccc;
border-radius: 15px;
}

.terms 
{
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  font: normal normal normal 13px/48px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.terms input {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.terms label {
  margin: 0px;
}

.terms label a {
  font: normal normal 13px/38px Poppins;
  letter-spacing: 0px;
  color: #04A7D0;
}

.terms .text-danger {
  flex-basis: 100%;
}


p{
margin-bottom: 10px;
}

.publication-one img{
width: 70%;
}

.publication-two img{
width: 50%;
}

.thumb-wrapper .publication-two{
height: 25px;
}
.thumb-wrapper .publication-one{
height: 50px;
}

.select-btn{
background-color: #04A7D0;
border: none;
color: #fff;
padding: 4px 25px;
border-radius: 25px;
font-size: 14px;
}

.price strike{
font-size: 12px;
color: #878787;
}

.price p{
font-size: 15px;
margin-bottom: 0px;
color: #000;
}

@media screen and (max-width: 768px){
  .select-btn {
    padding: 4px 10px;
    font-size: 10px;
  }

  .deliveryAddressFont1{
    font: normal normal normal 14px/15px Poppins;
    letter-spacing: 0px;
    color: #8B8B8B;
  }
  .deliveryAddressFont2{
    font: normal normal 600 15px/15px Poppins;
    letter-spacing: 0px;
    color: #000000;
  }

  .offers-box { margin-top: 40px!important;
  }

  /* .bottom-tab{
  display: flex;
    margin-top: 5%;
  }

  .total-price, .checkout{
  width: 50%;
    background: none;
  } */

  .apply-coupon{
    /* padding: 8px;
    font-size: 14px; */
    margin-left: 10px;
  }

  .checkout {
    padding: unset;
    position: relative;
    bottom: unset;
    left: unset;
  }
}

.blackClourV2{
  color: black;
}

.paymentLinkCh{
  text-decoration: underline;
  font: normal normal normal 13px/28px Poppins;
  letter-spacing: 0px;
  color: #04A7D0;
  display: flex;
  align-items: center;
}

.closBtn{
  background-color: red;
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
}

.addressWarning{
  padding: 10px;
}

.planAddition {
  margin-top: -10px!important;
  margin-bottom: -13px!important;
  text-align: center !important;
  font-size: 12px !important;
}

.planAdditionSelected{
  margin-top: -19px!important;
  margin-bottom: -8px!important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  font-size: 15px !important;
}


#formInput{
  border: 1px solid #d8d8d8;
  border-radius: 10px;
 box-shadow: 0 10px 10px rgb(59 59 59 / 10%);
}

.coupon-section p{
  font: normal normal bold 18px/38px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.coupon-section p:after {
  /* content:""; */
  /* position: absolute; */
  /* bottom: 0;
  left: 0;
  right: 0;
  height: 0.5em;
  border-top: 1px solid black;
  z-index: -1; */
}

.apply-btn{
  border-radius: 0px 10px 10px 0px;
  background-color: #017998;
  color: #fff;
}

.apply-coupon input{
  width: 80%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CDCDCD;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 10px;
}

.apply-coupon button{
  width: 20%;
}

.apply-coupon .applied{
  color: #52A104;
  font-weight: 400;
  font-size: 14px;
}

.apply-coupon .save{
  color: #F25D1D;
  font-weight: 400;
  font-size: 14px;
}

.checkout-img-main {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.checkout-img-main div {
  /* display: flex;
  flex-wrap: wrap;
  align-items: center; */
  padding: 5px 5px 0px 0px;
}

.checkout-left-first {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
}
.checkout-left-second {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 60px #00000029;
  border-radius: 8px;
  padding: 5px;
}

.order-details #details-box {
  display: flex;
  justify-content: space-between;
}

.offerIcon {
  background: #25add8;
  color: white;
  padding: 4px;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 3px;
}

#sign-in-title h4 {
  font: normal normal bold 28px/48px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.t-and-c-div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.t-and-c-div h4 {
  font: normal normal 600 14px/45px Poppins;
  letter-spacing: 0px;
  color: #EE2F35;
}
.saveAddress .modal-header {
  height: 110px;
}

.saveAddress h4 {
  font: normal normal bold 28px/55px Poppins;
  letter-spacing: 0px;
  color: #000000;
}
.saveAddress p {
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0px;
  color: #000000;
}

.saveAddress .btn {
  background: #04A7D0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  font: normal normal 600 15px/45px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  padding: 0px 30px;
}

#saveAdd {
  position: sticky;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}
#saveAdd img {
  position: relative;
  top: -50%;
  left: 50%;
  transform: translate(-50%, 0);
}

.addressPopStyle .form-group {
  text-align: start;
}