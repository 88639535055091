    .card-header
    {
        border-radius: 7px !important;
    }
    .active-order{
    background: linear-gradient(to right, #5AAC0B, #6BD009);
    }
    .progress-order{
    background: linear-gradient(to right, #f46b45, #eea849);
    }
    .expired-order{
    background: linear-gradient(to right, #cb2d3e, #ef473a);
    }

    .orderPenal{
        background-color: white;
        color: black;
    }

    .ulOrder{
        list-style-type: none;
        font-size: 15px;
    }

    .card-box {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 60px #00000014;
    }
    /* .proile-img-div{
      width: 72px;
      height: 72px;
    } */
    .proile-img{
      height: 100%;
      width: 100%;
      background: transparent 0% 0% no-repeat padding-box;
      border: 1px solid #EE2F35;
    }

    .profile-card-first{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }

    .profile-card-second{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    .profile-card-third{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: center;
      align-items: flex-start;
    }

    .profile-card__txt {
        display: flex;
        font-size: 18px;
        font-weight: 500;
        color: #000;
        word-wrap: break-word;
        overflow-wrap: break-word;
       
      }
      
      .profile-card__txt strong { 
        font-weight: 700;
      }

      .name {
        text-align: left;
        font: normal normal bold 16px/35px Poppins;
        letter-spacing: 0px;
        color: #000000;
        text-transform: capitalize;
      }
      .profile-small-txt {
        text-align: left;
        font: normal normal normal 12px/25px Poppins;
        letter-spacing: 0px;
        color: #000000;
      }
      .profile-small-txt span {
        word-break: break-all;
      }
      .profile-divider {
        border: 1px solid #e2e2e2;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
      }
      .profile-icon-mobile{
        width: 11px;
        height: 16px;
        background: transparent 0% 0% no-repeat padding-box;
      }
      .profile-icons-span{
        margin-right: 16px;
      }
      .profile-heading{
        font: normal normal 600 10px/25px Poppins;
      }

      .changeVendor-btn {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: stretch;
        width: 100%;
      }
      .changeVendor-btn a {
        position: absolute;
        background: #EE2F35 0% 0% no-repeat padding-box;
        border-radius: 8px;
        padding: 10px 50px;
        font: normal normal 600 12px / 25px Poppins;
        letter-spacing: 0px;
        color: #FFFFFF !important;
        text-transform: uppercase;
        bottom: -24px;
      }
      .edit-email{
        font: normal normal normal 10px / 26px Poppins;
      }
      .dashboardCoupon{
        position: absolute;
        right: 16px;
      }
      .mt-5-custom {
        margin-top: 3rem;
      }
      @media (max-width: 768px) {
        
        .mt-5-custom {
          margin-top: 0.5rem;
        }
        .nav-main .dropdown-menu {
          inset: 0px 95px auto auto !important;
        }
        .profilecard .MuiAccordionSummary-expandIcon{
          display: block;
        }
        .member-card {
          margin-bottom: 25px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: center;
          justify-content: space-evenly;
          align-items: center;
        }
        .profile-card-first {
          align-items: flex-start;
        }
        .all-orders {
          padding: 15px !important;
          margin-top: 20px;
        }
        .mx-auto-mobile-none{
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
      
      input, textarea {
        outline: none;
        background-clip: padding-box;
        box-shadow: none;
      }
      input, textarea {
        user-select: auto;           /* Standard */
        -webkit-user-select: auto;   /* Safari and older iOS versions */
        -moz-user-select: auto;      /* Older Firefox versions */
        -ms-user-select: auto;       /* Internet Explorer and Edge (legacy) */
      }
      input[type="checkbox"] {
        -webkit-appearance: checkbox;
        -moz-appearance: checkbox;
        appearance: checkbox;
      }