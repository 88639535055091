/* styles.css */

.cookie-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.cookie-consent {
  width: 40%;
  background-color: #f8a632;
  font-weight: 600;
  color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.cookie-consent button {
  padding: 8px 16px;
  background-color: #1480c4;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.cookie-consent h4 {
    text-transform:uppercase;
}
.consent-btn{
    display: flex;
    justify-content: flex-end;
}
.cookie-consent a{
    text-decoration: underline;
}
.cookie-consent button:hover {
  background-color: #0056b3;
}
