.loader {
    margin: 0 auto;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #f7941b;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .redirect {
      padding-top:50px;
      line-height:13px;
      margin-bottom:-25px;
  }
  .redirect span{
      color:#f7941d;
      font-size:16px;
      display:inline-block;
  }